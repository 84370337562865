.anotation-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
    border: 2px solid var(--dommus-color-secondary);
    border-radius: 5px;    
}

.anotation-item .anotation{
    max-width: calc(100vh - 100px);
    width: 100%;
}

.anotation-item .anotation .note-input{
    min-height: 70px;
    border: 1px solid #ddd;
    width: 100%;
    border-radius: 5px;    
}

.anotation-item .anotation .note-input:focus{
    outline: none;
    border-radius: 5px;
}

.anotation-item .btn-anotation-section{
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 0px 10px;
}


.anotation-item .btn-anotation{
    width: max-content;
    border: none;
    background: transparent;
    transition: all 300ms;
}

.anotation-item .btn-anotation:active{
    transform: scale(0.95);

}