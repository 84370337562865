.history-item {
  background-color: #ebebeb !important;
  padding: 10px;
  border: 1px solid #ccc;
}
.field-type {
  font-weight: bold;
}

.callbutton {
  border: 1px solid #ccc;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
}
