.dialer{
    width:300px;
    background-color: #fbfbfb;
    border-radius: 10px;
    padding: 10px 20px;
    position: fixed;
    z-index: 1050;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    bottom: 0px;
    cursor: pointer;
    height: max-content;
}


.number.in-call {
    background-color: var(--dommus-color-red);
    color:#fff;
}

.number.nb {
    border:none;
}
.number.nb:hover{
    background-color: #fff;
}

.header-section{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.header-section .title-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: var(--dommus-color-cinza)
}

.header-section .title-header svg{
    color: var(--dommus-color-2);
}

.dialer .header-section button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    padding: 0px;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #d1d1d1;
    color: #494949;
}

.dialer .call-to-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:#444;
    font-size:20px;
    box-sizing:border-box;
    margin: 10px 0px;
    padding: 3px 10px;
    background-color: #e5e4e4;
    border-radius: 10px;
}

.dialer svg{
    width: 20px;
}

.dialer .origem-section{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
}

.dialer .origem-section .origem-section span{
    color: var(--dommus-color-cinza);
}

.dialer .origem-section .origem-select{
    padding: 1px 5px;   
}

.dialer .numbers-section{
    display: flex;
    flex-direction: column;   
    align-items: center; 
    justify-content: center;
    gap: 5px;    
    background: #e5e4e4;
    border-radius: 10px;
    padding-top: 10px;
}

.dialer .numbers-section .numbers-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.dialer .numbers-section .number{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
    color:#444;
    background-color: #f9f9f9;
    width:60px;
    height:60px;
    font-size:26px;
    margin:0 7px 10px 7px;
    border:2px solid #c9c7c7;
    cursor:pointer;
}

.dialer .number:hover{
    background-color: #f3f3f3;
}

.dialer .footer-section{
    margin: 20px 0px;
}


.dialer .start-call{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    color: white;
    border-radius: 50%;
    width: 65px;
    height: 65px;
}

.dialer .start-call:hover {
    background-color: rgb(1, 112, 1);
}
