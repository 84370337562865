/* Variaveis */

:root {
    /* Cores */
    /* --dommus-color:#0a71b2; */
    --dommus-color: #183b64;
    /* --dommus-color-2:#f19d1f; */
    --dommus-color-2: #f7941d;
    /* --dommus-color-secondary:#64B5F6; */
    --dommus-color-secondary-light: #3074c3;
    --dommus-color-secondary: #265d9c;
    --dommus-color-hover: #dee2e6;
    --dommus-color-cinza: #868aa8;
    --dommus-color-green: #4CAF50;
    --dommus-color-red: #f44336;
    --dommus-color-dark: #353252;
    --dommus-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    --dommus-b-radius: 5px;
    --dommus-overflow-width: 6px;
    --dommus-overflow-height: 6px;


    /*  */
    --smooth: 200ms ease-in-out;
}

*::-webkit-scrollbar-track {
    background-color: #ddd;
}

*::-webkit-scrollbar {
    width: var(--dommus-overflow-width);
    height: var(--dommus-overflow-height);
    background-color: #ddd;
    border-radius: 10px;
}

body::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--dommus-color-secondary);
    border-radius: 10px;
}

.dommus-widgets-app{
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    top: 0;
    left: 0;
    padding: 5px 5px;
    background-color: white;
    border: 2px solid #ddd;
    border-radius: 50px;
    cursor: move;
    z-index: 200;
}