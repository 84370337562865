$border: #dddddd 1px solid;

.titleToggleAtendimentoContent {
  display: flex;
  .totalAtendimentos {
    margin-left: 0.5rem;
  }
}
.limparFiltros {
  margin-right: 0.3rem;
  font-weight: lighter;
  color: white;
  background-color: var(--dommus-color-secondary);
  border-radius: 0.5rem;
  padding: 0.3rem;
  // border-bottom: 1px solid var(--dommus-color-cinza);
  cursor: pointer;
  &:hover {
    background-color: var(--dommus-color-2);
  }
}

.data-inicio{
  color: #999;
}

.col-atendimento-info{
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;
  gap: 3px;
}
.tag-section{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.2rem;
}

.tag-nome {
  background-color: #f1a042;
  color: white;
  font-size: 11px;
  padding: 3px 5px;
  border-radius: 3px;
  margin-left: 4px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag-agrupada{
  background-color: #f1a042;
  color: white;
  font-size: 11px;
  padding: 3px 5px;
  border-radius: 3px;
  width: 2rem;
  margin-left: 4px;
  font-weight: 500;
}

.registroSection {
  display: flex;
}
.tipo-atendimento {
  font-size: 11px;
  font-style: italic;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.statusAtendimento {
  display: flex;
  justify-content: left;
  margin-top: 0.2rem;
  font-size: 11px;
  align-items: center;
}
.atendenteNome {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
  align-items: center;
}
.checkboxAtendimentosArea {
  display: flex;
  align-items: center;
  padding: 0.6rem 0.2rem 0.6rem 0.6rem;
}
#checkboxRegistro {
  transition: 0.2s;
  cursor: pointer;
}
#toggleCheckMultiSelect {
  transition: 0.2s;
  cursor: pointer;
}
.multiSelect {
  margin-left: 0.5rem;
  &:hover {
    color: var(--dommus-color-secondary);
  }
}
.menuRegisto {
  margin: 0.3rem 0.25rem 0 -0.7rem;
  background-color: var(--dommus-color-secondary);
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
#DropRegistro {
  padding: unset;
  background-color: unset !important;
  border: unset !important;
  //transform: scale(1.5);
  height: 22px;
  margin-top: 0.25rem;
}
#DropRegistro::after {
  display: none;
}
.opcaoMenu {
  text-align: initial;
  font-size: 0.9rem;
  // padding: 0.4rem 1rem 0.4rem 0.4rem;
  // margin: 0.2rem 0rem 0.2rem 0.4rem;
  .opcaoEditar {
    color: var(--dommus-color-secondary);
    .tituloEditar {
      color: var(--dommus-color-cinza);
      margin-left: 1rem;
    }
  }
  &:hover {
    background-color: unset;
    .tituloEditar {
      color: var(--dommus-color-secondary);
    }
  }
  .opcaoArquivar {
    color: var(--dommus-color-2);
    .tituloArquivar {
      color: var(--dommus-color-cinza);
      margin-left: 1rem;
    }
  }
  &:hover {
    background-color: unset;
    .tituloArquivar {
      color: var(--dommus-color-2);
    }
  }
  .opcaoExcluir {
    color: var(--dommus-color-red);
    .tituloExcluir {
      color: var(--dommus-color-cinza);
      margin-left: 1rem;
    }
  }
  &:hover {
    background-color: unset;
    .tituloExcluir {
      color: var(--dommus-color-red);
    }
  }
  .opcaoFinalizar {
    color: var(--dommus-color-green);
    .tituloFinalizar {
      color: var(--dommus-color-cinza);
      margin-left: 1rem;
    }
  }
  &:hover {
    background-color: unset;
    .tituloFinalizar {
      color: var(--dommus-color-green);
    }
  }
}
.editarAtendimento {
  display: flex;
  // z-index: 999;
}
#DropAtendimento {
  // padding: 0.3rem;
  padding: 0;
  margin: 0;
  color: #353252;
  border-color: #dddddd;
  background-color: unset !important;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: unset !important;
  // transform: scale(1.5);
}
#DropAtendimento::after {
  // display: flex;
  display: none;
  color: var(--dommus-color-cinza);
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.naoLidas {
  margin: -0.6rem 0 0 1rem;
  display: inline;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  // white-space: nowrap;
  // vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: absolute;
  color: #fff;
  background-color: #dc3545;
}

.direcaoRegistro {
  margin: -0.5rem 0 0 -1.8rem;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  // white-space: nowrap;
  // vertical-align: baseline;
  border-radius: 0.25rem!important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  color: #fff!important;
  --bs-bg-opacity: none!important;
  background-color: none!important;
}
.registrosAtendimento {
  .nav-pills {
    .nav-link {
      margin-bottom: 0rem;
      padding: 10px 8px 10px 10px;
    }
    .nav-link:last-child {
      padding: 10px 8px 10px 10px;
    }

    .nav-link.active {
      border-radius: 0.25rem 0 0 0.25rem;
      color: #fff;
      background-color: var(--dommus-color-secondary);
      margin-right: 0;
      .data-inicio{
        color:white;
      }
      .data-ultima-atualizacao{
        color:white;
      }
    }
  }
  .dommus-toggle-height-fixed {
    padding-left: 5px;
  }
}

.registroAtendimentoToggle {
  justify-content: center !important;
}

.dataToggle {
  display: flex;
  justify-content: end !important;
  flex: auto;
  margin-right: 1rem;
}

.row-atendimento-info-footer{
  padding-left: 5px;
  .column-atendimento-info-footer{
    padding: 0px !important;
  }
}

.registros-container {
  width: -webkit-fill-available;
  // display: flex;

  .navIntemRegistro {
    display: flex;
    flex-direction: row;
  }
  .registro {
    transition: 0.2s;
    color: #353252;
    background: white;
    // border: none;
    margin: 5px 5px 0 0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: 100%;
    font-size: 0.9em;

    .registo-informacoes-row{
      padding: 0px 5px;
    }

    &:hover {
      transition: 0.2s;
      color: black;
      background-color: var(--dommus-color-hover);
      margin-bottom: 7px;
      box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.5);
    }
    &:active {
      // margin: 1rem 0 1rem 1rem;
      color: #f5f5f5;
      background-color: var(--dommus-color-secondary);
    }
    .referencia {
      display: flex;
      text-align: left;
      font-size: 11px;
      align-items: left;
      flex-direction: row;
      justify-content: flex-start;
    }
    .opv {
      margin-left: 2px;
    }
    .dot {
      height: 5px;
      width: 5px;
      background-color: var(--dommus-color-2);
      margin-left: 2px;
      border-radius: 3px;
    }

    .data {
      text-align: right;
      font-size: 11px;
    }
    .contato {
      text-align: left;
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .nome {
      max-width: 15rem;
      text-align: left;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .data-ultima-atualizacao{
      text-align: right;
      font-size: 14px;
      color: #999;
    }
    .colunaIcone {
      width: 10%;
      margin-right: 10px;
      flex-grow: 0 !important;
    }
    .registroContatoNome {
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding: 0px 5px;
    }
    .oportunidadeProcesso {
      display: flex;
      flex-direction: column;
      justify-itens: right;
      gap: 2px;
      padding: 0px;
    }
    .registro-col {
      justify-content: center;
      height: 115%;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;

      margin: -10px -20px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      &.icon {
        width: 3.5rem;
        margin-top: -1.86rem;
        flex-grow: 0;
        svg {
          width: auto;
          font-size: 1.5rem;
        }
      }
    }
  }
}
.registros-container :last-child {
  .registro {
    &:hover {
      // margin-top: 10px;
      margin-bottom: 0px;
    }
  }
}

.situacaoAtendimentoBadge {
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-processo-info {
  font-size: 14px;
  margin-right: 5px;
}

.data {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

@media (min-width: 768px) {
  .registros-container .registro .registroContatoNome {
    font-size: 12px;
  }
  .registros-container .registro .contato {
    font-size: 11px;
  }
  .registros-container .registro .oportunidadePorcesso {
    width: 100%;
    font-size: 12px;
  }
  .atendenteNome {
    font-size: 10px;
  }
  .atendenteNome svg {
    font-size: 10px;
  }

}
