.widget-button {
    width: 50px;
    height: 50px;
    background-color: var(--dommus-color-2);
    border-radius: 50%;
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}

.widget-button svg{
    width: 20px;
    color: white;
}
