.btn-history{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EAB704;
    border-radius: 50%;
    width: 2.7rem;
    height: 2.7rem;
}

.btn-history:hover{
    background-color: #d8ab04;
}

.btn-history:disabled{
    background-color: var(--dommus-color-cinza);
}

.btn-history svg{
    size: 20px;
}