.in-call {
  width: 300px;
  background-color: #fbfbfb;
  border-radius: 10px;
  padding: 10px 20px;
  position: absolute;
  z-index: 1050;
  right: 75px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  bottom: 0px;
  cursor: pointer;
  height: max-content;
}

.in-call .header-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.in-call .header-section .title-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: var(--dommus-color-cinza);
}

.in-call .header-section .title-header svg {
  color: var(--dommus-color-2);
}

.in-call .header-section button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 0px;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #d1d1d1;
  color: #494949;
}

.in-call .user-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  gap: 10px;
}

.in-call .user-section .user-name {
  font-size: larger;
  font-weight: 500;
}

.in-call .user-section .user-phone {
  color: #666666;
  font-weight: 400;
}

.in-call .call-info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.in-call .call-info-section span {
  color: #ac0404;
  font-weight: 700;
}

.in-call .call-info-section .starting-call {
  font-size: 20px;
}

.in-call .call-info-section .time-call {
  font-size: 28px;
}

.in-call .call-buttons-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.in-call .call-buttons-section .btn {
  border-radius: 50%;
  color: white;
}

.in-call .call-buttons-section .small-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  width: 2.7rem;
  height: 2.7rem;
  transition: 300ms all;
}
.in-call .call-buttons-section .small-button:hover{
    transform: scale(1.02);
}

.in-call .call-buttons-section .big-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  width: 3.5rem;
  height: 3.5rem;
  transition: 300ms all;  
}

.in-call .call-buttons-section .big-button:hover{
    transform: scale(1.02);
}

.in-call .btn-anotation {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 2px 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  transition: 300ms all;
}

.in-call .btn-anotation:active{
  transform: scale(0.95);
}

.in-call .btn-anotation span{
  color: #575555;
} 

.in-call .btn-anotation svg{
  color: #0039cc;
}


.in-call .call-buttons-section .mute-call {
  color: #353252;
  border: 2px solid #353252;
}

.in-call .call-buttons-section .is-mute-call {
  color: white;
  background-color: #353252;
}
.in-call .call-buttons-section .hangup-call {
  background-color: #ac0404;
}
