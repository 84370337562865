.linkFile {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: bold;
  color: var(--white);
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none !important;
  &:hover{
    color: var(--dommus-color-dark);
  }
}
