.historyItem {
  cursor: pointer;
  padding: 10px;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.historyItem.selected {
  border: 1px solid #a7b3c1;
}

.historyItem .status {
  background-color: #ca965c;
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.chat-history{
  height: calc(100vh - 10rem);
  overflow-y: auto;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px 5px 5px 5px;
  width: 100%;
}

.messageDetail {
  overflow: hidden;
  height: calc(100vh - 10rem);
  border: 1px solid #ddd;
  border-radius: 5px 5px 5px 5px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.messageContent {
  display: flex;
  flex-direction: column;
  overflow: unset;
}

.messageContent .messageItem {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #ccc;
  border-radius: 10px;
  border-bottom-left-radius: 0;
}

.messageDetail .messageItem.enviado {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #265d9c;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  color: #fff;
}

.messageDetail .inputMessage {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.messageDetail .sendButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.unreadMessagesCount {
  background-color: #265d9c;
  color: #fff;
  font-weight: bold;
  position: absolute;
  height: 20px;
  width: 20px;
  top: -12px;
  left: 0px;
  border-radius: 50%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-modal .modal-dialog{
  --bs-modal-width: 82.5rem;
}
