.card-anotations {
  min-width: 600px;
  height: 412px;
  background-color: #fbfbfb;
  border-radius: 10px;  
  position: absolute;
  z-index: 1054;
  right: 385px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  bottom: 0px;
  cursor: pointer;
  z-index: 1;    
}

.card-anotations .card-anotation-content{
  position: relative;
  height: 100%;  
  display: flex;
  flex-direction: column;
  max-width:  calc(100vh - 100px);
}

.card-anotations .card-anotation-content .anotations-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;
}

.card-anotations .card-anotation-content .anotations-header .anotation-header-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  
}
.card-anotations .card-anotation-content .anotations-header .anotation-header-title span{
  color: var(--dommus-color-cinza);
}

.card-anotations .card-anotation-content .anotations-header .anotation-header-title svg{
  color: #0039cc;
}

.card-anotations .card-anotation-content .anotations-header button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 0px;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #d1d1d1;
  color: #494949;
}

.card-anotations .card-anotation-content .anotations-body{
  display: flex;  
  flex-direction: column;
  max-height: 323px;
  gap: 5px;
  padding: 10px 10px 40px 10px;
  overflow-y: auto;
}

.card-anotations .card-anotation-content .anotation-input-section{
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  flex-direction: row;  
  gap: 10px;
  background-color: #efefef;
  align-items: flex-end;
  border-radius: 0px 0px 5px 5px;
  margin-top: 5px;
  padding: 10px 10px;
  min-height: 40px;
}

.card-anotations .card-anotation-content .anotation-input-section button{
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--dommus-color-secondary);
  color: white;
  border-radius: 5px;
  max-height: 50px;
  min-height: 30px;
  transition: all 300ms;
}

.card-anotations .card-anotation-content .anotation-input-section button:active{
  transform: scale(0.95);
}

.card-anotations .card-anotation-content .anotation-input-section .textarea{
  width: 100%;
  cursor: text;
  overflow-y: auto;
  font-size: 16px;
  min-height: 30px;
  max-height: 60px;
  line-height: 15px;
  overflow-wrap: break-word;
  background-color: white;
  border: 1px solid #ddd;  
  border-radius: 5px;
  padding: 6px 10px 5px 10px;
}

.card-anotations .card-anotation-content .anotation-input-section .textarea[contenteditable]:empty::before {
  content: "Mensagem ...";
  color: #666;
}

.card-anotations .card-anotation-content .anotation-input-section .textarea:focus{
  outline: none;
  border-radius: 5px;
}

